#about-container {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  /* min-height: 100vh; */
}

.about-title {
  text-align: center;
  font-weight: bold;
  color: #374457;
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: Roboto, sans-serif;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-content p {
  line-height: 1.5rem;
  font-size: 1.5rem;
  text-align: left;
}
.about-content #about-image {
  width: 20vw;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  -o-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  border-radius: 50%;
}
.about-content .about-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
}

@media only screen and (max-width: 1100px) {
  #about-container {
    min-height: 0;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-content {
    font-size: 0.75rem;
  }
  .about-content .about-table {
    flex-direction: column;
  }
  .about-content img {
    width: 75%;
  }
}


