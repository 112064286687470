.card {
  display: grid;
  background-color: white;
  grid-template: auto 1.15rem 1fr/1fr;
  width: 50%;
  margin: 1.5rem 0;
  padding: 1rem;
  font-family: Roboto, sans-serif;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  -webkit-transition: box-shadow 0.2s, width 0.3s;
  -moz-transition: box-shadow 0.2s, width 0.3s;
  -ms-transition: box-shadow 0.2s, width 0.3s;
  -o-transition: box-shadow 0.2s, width 0.3s;
  transition: box-shadow 0.2s, width 0.3s;
}
.card .expanded-text {
  height: auto;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  -ms-transition: max-height 0.3s;
  -o-transition: max-height 0.3s;
  transition: max-height 0.3s;
}
.card .normal-text {
  height: auto;
  max-height: 100vh;
  overflow: hidden;
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  -ms-transition: max-height 0.3s;
  -o-transition: max-height 0.3s;
  transition: max-height 0.3s;
}
.card:hover {
  -webkit-box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
}
.card.small .card-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.card.small .card-details {
  align-self: flex-start;
  font-size: 1.1rem;
}
.card.small .image-container {
  align-self: center;
  width: 100%;
}
.card.small .image-container img {
  height: 100%;
}
.card.large {
  width: 75%;
}
.card.large .card-content {
  align-content: center;
}
.card.large .image-container {
  justify-content: center;
  width: 75%;
}
.card.large .expanded-text {
  max-height: 100vh;
}
.card.large .normal-text {
  max-height: 0;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: 1/1;
}

.card-header-details {
  display: flex;
}

.card-title {
  display: inline;
  font-weight: bold;
  color: #374457;
  font-size: 2rem;
  line-height: normal;
}

.card-header-language {
  display: inline-flex;
  justify-content: center;
  background-color: #fbbd08;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.25rem 0.35rem;
  margin-right: 0.25rem;
}

.card-buttons {
  color: #374457;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}
.card-buttons:hover {
  color: #556070;
}

.card-date {
  font-weight: 300;
  color: black;
  grid-row: 2;
  font-size: 1.05rem;
}

.card-content {
  grid-row: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.card-details {
  align-self: flex-start;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

ul {
  list-style-type: circle;
}

.image-container {
  width: 100%;
  text-align: center;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
}

img {
  width: 75%;
}

@media only screen and (max-width: 1100px) and (orientation: portrait) {
  .card.small .card-content {
    display: flex;
  }
  .card.small .card-details {
    align-self: center;
    font-size: 1.1rem;
    width: 100%;
  }
  .card.small .image-container {
    align-self: center;
    width: 100%;
  }
  .card.small .image-container img {
    height: 100%;
  }

  .card {
    width: 90%;
    font-size: 3vw;
  }
  .card.large {
    width: 90%;
  }
  .card.large .image-container {
    width: 100%;
  }

  .card:hover {
    -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
  }

  .card-title {
    font-size: 135%;
  }

  .card-date {
    font-size: 2.7vw;
  }
}

/*# sourceMappingURL=ProjectCard.css.map */
