#navbar-grid {
  display: grid;
  grid-template: 2.75em / 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  background-color: #374457;
  color: #fafafa;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  border-top: solid 1px #27303d;
  -webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
}

.navbar-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -ms-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  #navbar-grid {
    display: none;
  }
}
.navbar-link:hover {
  color: inherit;
  background: #556070;
}

.navbar-email {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  opacity: 0;
  width: 100%;
  background-color: #6c6c6c;
  color: #fff;
  text-align: center;
  border-radius: 0.25em;
  padding: 0.25em 0.25em;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 105%;
  right: 0%;
  /* margin-left: -60px; */
  -webkit-transition: opacity 0.2s ease 0s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease 0.3s;
}
