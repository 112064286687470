@mixin box-shadow($args...) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  -ms-box-shadow: $args;
  -o-box-shadow: $args;
  box-shadow: $args;
}

#about-container {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  min-height: 100vh;
}

.about-title {
  text-align: center;
  font-weight: bold;
  color: #374457;
  font-size: 3rem;
  padding: 0.35rem;
  padding-bottom: 2rem;
  font-family: Roboto, sans-serif;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    line-height: 1.5rem;
    font-size: 1.5rem;
    text-align: left;
  }

  #about-image{
    width: 20vw;
    @include box-shadow(0 1px 5px rgba(0, 0, 0, 0.46));
    border-radius: 50%;
    // background: none repeat scroll 0 0 #E9E9E9;
  }

  .about-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
  }
}

@media only screen and (max-width: 1100px) and (orientation: portrait) {
  #about-container {
    min-height: 0;
  }
  .about-title {
    font-size: 2rem;
  }

  .about-content {
    // margin-right: 10%;
    font-size: 0.75rem;
    .about-table {
      flex-direction: column;
    }
    img {
      width: 75%;
    }
  }
}
